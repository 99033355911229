import { useState, useCallback, useEffect } from 'react'

interface UsePagination<T> {
  elements: T[]
  nextPage: () => void
  hasMore: boolean
}

export default function usePagination<T>(
  items: T[],
  pageSize = 10
): UsePagination<T> {
  const [cursor, setCursor] = useState(Math.min(pageSize, items.length))

  const nextPage = useCallback(
    () => setCursor((c) => Math.min(items.length, c + pageSize)),
    [items.length, pageSize]
  )

  // Sometimes, on first render, we don't have an items list and the cursor will
  // be 0. If the items.length changes between renders and we're at 0, do the
  // initial state logic
  useEffect(
    () => setCursor((c) => (c === 0 ? Math.min(pageSize, items.length) : c)),
    [items.length, pageSize]
  )

  return {
    elements: items.slice(0, cursor),
    nextPage,
    hasMore: cursor < items.length,
  }
}
