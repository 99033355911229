import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { graphql, PageProps } from 'gatsby'
import {
  PageContextType,
  PageDataContextType,
} from '@talentinc/gatsby-theme-ecom/types/page'
import Layout from '@talentinc/gatsby-theme-ecom/components/Layout'
import { CategoryBySlug } from '@talentinc/gatsby-theme-ecom/types/category'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import uniqBy from 'lodash/uniqBy'

import HeaderV2 from '../components/HeaderV2'
import Footer from '../components/Footer'
import SEO from '@talentinc/gatsby-theme-ecom/components/SEO'
import CategoryPageTemplate from '@talentinc/gatsby-theme-ecom/components/Templates/Category'
import CallToAction from '@talentinc/gatsby-theme-ecom/components/CallToAction'
import { CTAVariants } from '@talentinc/gatsby-theme-ecom/types/cta'
import XRayWrapper from '@talentinc/gatsby-theme-ecom/components/XRay/XRayWrapper'

const CategoryTemplate: React.FC<
  PageProps<PageDataContextType & CategoryBySlug, PageContextType>
> = (props) => {
  const {
    category,
    posts,
    defaultInListCallToAction,
    defaultFooterCallToAction,
    ...pageData
  } = props.data
  const { t } = useTranslation()
  const { classes } = useStyles()

  // Posts with multiple categories show up twice or more because there is no
  // group by in graphql. This is a temporary hack, we might need to pass in
  // contentful IDs to the query from gatsby-node to keep the page size small
  // here.
  posts.nodes = uniqBy(posts.nodes, (p) => p.contentful_id)

  return (
    <Layout
      pageData={pageData}
      pageTitle={t('category.pageTitle', { categoryName: category.name })}
    >
      <SEO
        {...(category.seo || {})}
        category={category}
        categoryPosts={posts.nodes}
        noIndex={props.pageContext.noIndex}
      />
      <HeaderV2 showBlogNavigation />
      <XRayWrapper id={category.contentful_id}>
        <CategoryPageTemplate
          categoryName={category.name}
          posts={posts.nodes}
          cta={category.inListCallToAction || defaultInListCallToAction}
        />
        <CallToAction
          cta={{
            ...(category.footerCallToAction || defaultFooterCallToAction),
            variant: CTAVariants.FullWidthFooter,
            classes: {
              header: classes.ctaHeader,
              root: classes.ctaRoot,
            },
          }}
        />
      </XRayWrapper>
      <Footer />
    </Layout>
  )
}

const useStyles = makeStyles()({
  ctaHeader: {
    '& > *': {
      marginBottom: '2.75em !important',
    },
  },
  ctaRoot: {
    paddingBottom: '3.5em',
    paddingTop: '6em',
  },
})

export const query = graphql`
  query CategoryBySlugAndBrand($categorySlug: String!, $brandName: String!) {
    category: contentfulCategory(
      slug: { eq: $categorySlug }
      brand: { elemMatch: { name: { eq: $brandName } } }
    ) {
      contentful_id
      name
      slug
      seo {
        ...SEO
      }
      inListCallToAction {
        ...CallToAction
      }
      footerCallToAction {
        ...CallToAction
      }
    }
    defaultInListCallToAction: contentfulCallToAction(
      contentful_id: { eq: "2HcfGVOhMDVAlMifuyk8d0" }
    ) {
      ...CallToAction
    }
    defaultFooterCallToAction: contentfulCallToAction(
      contentful_id: { eq: "KHE8JtNy3WTTUl5ZaVIaR" }
    ) {
      ...CallToAction
    }
    posts: allContentfulPost(
      filter: {
        categories: { elemMatch: { slug: { eq: $categorySlug } } }
        brand: { elemMatch: { name: { eq: $brandName } } }
        seo: { noIndex: { ne: true } }
      }
      sort: { fields: publishedAt, order: DESC }
    ) {
      nodes {
        ...PostLink
      }
    }
    brand: contentfulBrand(name: { eq: $brandName }) {
      ...Brand
    }
    headerV2: contentfulHeaderV2(
      brand: { elemMatch: { name: { eq: $brandName } } }
    ) {
      ...HeaderV2
    }
    footer: contentfulFooter(brand: { name: { eq: $brandName } }) {
      ...Footer
    }
    locales: allLocale(filter: { language: { eq: "en" } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default CategoryTemplate
