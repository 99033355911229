import React from 'react'
import { Box, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import Image from '../ContentfulEntries/Image'
import Link from '../Link'
import { SampleLink } from '../../types/landingPage'
import useUrlPathBuilder from '../../hooks/useUrlPathBuilder'

interface Props {
  link: SampleLink
}

const LandingPageSample: React.FC<Props> = ({ link }) => {
  const { classes } = useStyles()
  const { blogPrefix, urlPathBuilder } = useUrlPathBuilder()

  return (
    <Box
      className={classes.box}
      component={(props) => (
        // @ts-ignore
        <Link to={urlPathBuilder([blogPrefix, link.slug])} {...props} />
      )}
      // @ts-ignore
      elevation={0}
    >
      {link.featuredImages.length > 0 && (
        <Image className={classes.image} asset={link.featuredImages[0]} />
      )}
      <Typography className={classes.title} variant="body1">
        {link.title}
      </Typography>
    </Box>
  )
}

const useStyles = makeStyles()((theme) => ({
  box: {
    backgroundColor: 'transparent',
  },
  image: {
    backgroundColor: theme.palette.background.default,

    '&:hover': {
      boxShadow: `0 .25em .94em 0 ${theme.palette.divider}`,
    },
  },
  title: {
    color: theme.palette.text.primary,
    marginTop: '.43em',
  },
}))

export default LandingPageSample
