import { Button } from '@mui/material'
import { withStyles } from 'tss-react/mui'

// Neutral button that is gray but changes colors on hover. Handles both the
// contained and outlined variants
const NeutralButton = withStyles(Button, (theme) => ({
  contained: {
    color: '#fff',
    backgroundColor: theme.palette.text.hint,
    borderColor: theme.palette.text.hint,
  },
  containedPrimary: {
    '&:hover, &:active': {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
  containedSecondary: {
    '&:hover, &:active': {
      backgroundColor: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    },
  },
  outlined: {
    borderColor: theme.palette.divider,
    backgroundColor: 'transparent',
  },
  outlinedPrimary: {
    '&:hover, &:active': {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
  outlinedSecondary: {
    '&:hover, &:active': {
      backgroundColor: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    },
  },
}))

export default NeutralButton
