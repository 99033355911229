import React from 'react'
import { Container, Grid, Box, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import HeroPost from './HeroPost'
import LandingPagePost from './LandingPagePost'
import LandingPageSample from './LandingPageSample'
import CallToAction from '../CallToAction'
import {
  Section,
  SectionLink as SectionLinkType,
} from '../../types/landingPage'
import Link from '../Link'
import useUrlPathBuilder from '../../hooks/useUrlPathBuilder'

interface SectionProps {
  section: Section
}

interface LooseProps {
  title?: string
  links: SectionLinkType[]
}

type Props = (SectionProps | LooseProps) & {
  limit?: number
  layout?: 'flex' | 'grid' | 'gridExtraSmall'
  headerClassName?: string
}

const LandingPageSection: React.FC<Props> = ({
  limit = 25,
  layout,
  headerClassName,
  ...props
}) => {
  const { classes, cx } = useStyles()
  const { urlPathBuilder, blogPrefix } = useUrlPathBuilder()
  const { t } = useTranslation()
  const section = 'section' in props ? props.section : props

  return (
    <Container className={classes.main} disableGutters>
      {section.title && (
        <Box className={classes.header}>
          <Typography
            className={cx(classes.headerTitle, headerClassName)}
            variant={'h2'}
          >
            {section.title}
          </Typography>
          {'category' in section &&
          section.category &&
          typeof section.category === 'object' ? (
            <Link
              to={urlPathBuilder([
                blogPrefix,
                'category',
                'slug' in section.category &&
                typeof section.category.slug === 'string'
                  ? section.category.slug
                  : '',
              ])}
              className={classes.categoryLink}
            >
              {t('landingPageSection.viewAll')}
            </Link>
          ) : null}
        </Box>
      )}

      <Grid
        className={cx({
          [classes.container]: layout === 'flex',
          [classes.containerSmall]: layout === 'grid',
          [classes.containerExtraSmall]: layout === 'gridExtraSmall',
        })}
      >
        {section.links.slice(0, limit).map((link) => (
          <SectionLink key={link.contentful_id} {...link} />
        ))}
      </Grid>
    </Container>
  )
}

export const SectionLink: React.FC<SectionLinkType> = (link) => {
  const { classes } = useStyles()
  switch (link.__typename) {
    case 'ContentfulPost':
      return (
        <Grid item className={classes.postContent} key={link.contentful_id}>
          <LandingPagePost link={link} />
        </Grid>
      )
    case 'ContentfulCallToAction':
      return (
        <Grid item className={classes.postContentCTA} key={link.contentful_id}>
          <CallToAction cta={link} />
        </Grid>
      )
    case 'ContentfulHeroPost':
      return <HeroPost link={link} key={link.contentful_id} />
    case 'ContentfulPostResumeSample':
      return (
        <Grid item className={classes.resumeContent} key={link.contentful_id}>
          <LandingPageSample link={link} />
        </Grid>
      )
  }
}

const useStyles = makeStyles()((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerTitle: {
    fontSize: '1.812em',
    fontWeight: 'bold',
    lineHeight: 1.28,
    alignSelf: 'self-start',
    marginBottom: '1em',
  },
  categoryLink: {
    fontSize: '1.125em',
    lineHeight: '1.5555555556em',
    letterSpacing: 0,
  },
  main: {
    maxWidth: '100%',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'start',
    padding: '3.75em 1% 0 1%',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'unset',
      padding: '3.75em 40px 0 40px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '3.75em 24px 0 24px',
    },
  },
  container: {
    display: 'flex',
    flexFlow: 'row wrap',
    // margin:'0 1em',
    gridColumnGap: '2em',
    [theme.breakpoints.down('lg')]: {
      gridColumnGap: '3em',
      '& > :nth-child(1)': { order: 1 },
      '& > :nth-child(2)': { order: 2 },
      '& > :nth-child(3)': { order: 4 },
      '& > :nth-child(4)': { order: 3 },
      '& > :nth-child(5)': { order: 5 },
      '& > :nth-child(6)': { order: 6 },
      '& > :nth-child(7)': { order: 7 },
    },
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexFlow: 'row wrap',
    },
  },
  containerSmall: {
    display: 'flex',
    flexFlow: 'row wrap',
    '@supports (display: grid)': {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, minmax(20rem, 1fr))',
      gridTemplateRows: 'max-content',
      gridColumnGap: '2em',
      [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: 'repeat(2, minmax(20rem, 1fr))',
        gridColumnGap: '3em',
      },
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
      },
    },
  },
  containerExtraSmall: {
    display: 'flex',
    flexFlow: 'row wrap',
    '@supports (display: grid)': {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, minmax(17rem, 1fr))',
      gridTemplateRows: 'max-content',
      gridColumnGap: '2em',
      gridRowGap: '3.5em',
      [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: 'repeat(2, minmax(20rem, 1fr))',
        gridColumnGap: '3em',
      },
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
      },
    },
    marginBottom: '3.61em',
  },
  postContentCTA: {
    flexGrow: 1,
    minWidth: '20rem',
    flexBasis: '30%',
    marginBottom: '2em',
    marginRight: '0em',

    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      marginRight: '2em',
    },
    '@media all and (max-width:60rem) and (-ms-high-contrast: none), (-ms-high-contrast: active) ':
      {
        marginRight: '0',
      },
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('md')]: {
      flexGrow: 0,
      minWidth: 0,
      flexBasis: '100%',
    },
  },
  postContent: {
    flexGrow: 1,
    minWidth: '20rem',
    flexBasis: '30%',
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    marginBottom: '2em',
    marginRight: '0em',

    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      marginRight: '2em',
    },
    '@media all and (max-width:60rem) and (-ms-high-contrast: none), (-ms-high-contrast: active) ':
      {
        marginRight: '0',
      },
    '&:hover': {
      boxShadow: '0 4px 15px 0 #d3d3d3',
    },

    [theme.breakpoints.down('md')]: {
      flexGrow: 0,
      minWidth: 0,
      flexBasis: '100%',
    },
  },
  resumeContent: {
    flexGrow: 1,
    flexBasis: '20%',
    marginBottom: '2em',
    marginRight: '0em',

    //For IE 11
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      marginRight: '2em',
    },

    [theme.breakpoints.down('md')]: {
      flexGrow: 0,
      minWidth: 0,
      flexBasis: '100%',
    },
  },
}))

export default LandingPageSection
