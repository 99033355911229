import React, { useMemo, useCallback } from 'react'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import LandingPageSection from '@talentinc/gatsby-theme-ecom/components/LandingPage/LandingPageSection'
import usePagination from '@talentinc/gatsby-theme-ecom/hooks/usePagination'
import useEvents from '@talentinc/gatsby-theme-ecom/hooks/useEvents'
import { PostLink } from '../../types/landingPage'
import NeutralButton from '../Buttons/NeutralButton'
import { CTA, CTAVariants } from '../../types/cta'

interface Props {
  categoryName: string
  posts: PostLink[]
  cta: CTA
}

const pageSize = 9

const CategoryPageTemplate: React.FC<Props> = ({
  categoryName,
  posts,
  cta,
}) => {
  const { classes } = useStyles()
  const sendEvent = useEvents()
  const { t } = useTranslation()
  const postsWithCTASplicedIn = useMemo(() => {
    // We want to show the CTA ad in the middle row of each page but in a random
    // column. We assume that we want to have a 3x3 grid (for desktop) because
    // on smaller grids, the distribution will be nicer.
    const ctaIndexes: number[] = []

    for (let i = 0; i < posts.length; i += pageSize) {
      // For the first CTA on the first page, it needs to be in a static place
      // for the initial render.
      if (i === 0) {
        ctaIndexes.push(3)
        continue
      }

      ctaIndexes.push(
        i + Math.floor(3 / 2) * 3 + Math.floor(Math.random() * 3) - i / pageSize
      )
    }

    return posts.flatMap((post, i) => {
      if (ctaIndexes.includes(i)) {
        return [
          {
            ...cta,
            variant: CTAVariants.Sidebar,
            classes: {
              root: classes.ctaRoot,
              image: classes.ctaImage,
              header: classes.ctaHeader,
              actions: classes.actions,
            },
          },
          post,
        ]
      }

      return post
    })
    // I'm not tracking all the values here because the main ones are objects
    // that are always changing.
    /* eslint-disable-next-line */
  }, [posts.length, cta.contentful_id])

  const {
    elements: paginatedPosts,
    nextPage,
    hasMore,
  } = usePagination(postsWithCTASplicedIn, pageSize)

  const handleNextPage = useCallback(() => {
    nextPage()
    sendEvent({
      event: 'Category Pagination Load More Click',
      variables: {
        pagination: {
          page: paginatedPosts.length / pageSize + 1,
          pageSize,
          totalItems: posts.length,
        },
      },
    })
  }, [nextPage, sendEvent, paginatedPosts.length, posts.length])

  return (
    <Box className={classes.root}>
      <Box className={classes.containerInner}>
        <LandingPageSection
          title={categoryName}
          links={paginatedPosts}
          limit={paginatedPosts.length}
          headerClassName={classes.header}
          layout="grid"
        />
        {hasMore && (
          <Box className={classes.buttonContainer}>
            <NeutralButton onClick={handleNextPage} variant="outlined">
              {t('search.showMore')}
            </NeutralButton>
          </Box>
        )}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  containerInner: {
    margin: '0 auto',
    maxWidth: '100%',
  },
  header: {
    fontFamily: theme.typography.h1.fontFamily,
  },
  ctaRoot: {
    backgroundColor: theme.palette.primary.dark,
    height: '100%',
    justifyContent: 'center',
  },
  ctaImage: {
    '&&': {
      width: '70%',
    },
  },
  ctaHeader: {
    '&& > *': {
      fontFamily: theme.typography.h1.fontFamily,
      fontSize: '1.5em',
    },
  },
  buttonContainer: {
    maxWidth: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '.5em 6% 3.5em 6%',
  },
  actions: {
    margin: 'auto',
    width: 'initial',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}))

export default CategoryPageTemplate
