import React from 'react'
import { Card, Grid, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { ArrowForwardIos } from '@mui/icons-material'
import Link from '../Link'
import { HeroLink } from '../../types/landingPage'
import RichText from '../RichText'

import useUrlPathBuilder from '../../hooks/useUrlPathBuilder'

interface Props {
  link: HeroLink
}

const HeroPost: React.FC<Props> = ({ link }) => {
  const { classes } = useStyles()
  const { blogPrefix, urlPathBuilder } = useUrlPathBuilder()

  return (
    <Card className={classes.postContentHero} elevation={0}>
      <Typography className={classes.header}>
        <RichText rawBody={link.quote.raw} />
      </Typography>
      <Typography className={classes.author} variant={'caption'}>
        {[link.author.name, link.author.title].filter((p) => !!p).join(', ')}
      </Typography>
      <Link
        to={urlPathBuilder([blogPrefix, link.link.slug])}
        className={classes.readArticle}
      >
        <Grid
          container
          className={classes.cta}
          direction="row"
          alignItems="center"
        >
          <Typography component="h2">{link.ctaText}</Typography>
          <ArrowForwardIos className={classes.icon} />
        </Grid>
      </Link>
    </Card>
  )
}

const useStyles = makeStyles()((theme) => ({
  postContentHero: {
    display: 'flex',
    flexDirection: 'column',
    gridColumn: 'auto / span 3',
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    padding: '3.3125em 4em',
    margin: '0 7em 4.5em',
    [theme.breakpoints.down('lg')]: {
      gridColumn: 'auto / span 2',
      margin: '0 0 4.5em',
    },
    [theme.breakpoints.down('md')]: {
      gridColumn: 'auto / span 1',
    },
  },
  author: {
    margin: '1.43125em 0 1.64375em',
  },
  cta: {
    color: theme.palette.background.default,
  },
  header: {
    '& *': {
      fontFamily: theme.typography.h1.fontFamily,
      fontSize: '1.8125rem',
      fontWeight: 700,
      lineHeight: 1.28,
    },
  },
  icon: {
    fontSize: '1.1rem',
    height: '1.1em',
    paddingBottom: '.33rem',
    marginLeft: '.43em',
    width: '1.1em',
    alignSelf: 'flex-end',
  },
  readArticle: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

export default HeroPost
